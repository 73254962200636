<template>
    <div class="button-feedback">
        <a href="https://ylands.com/community/" target="_blank">
            <transition name="fade">
                <div
                    v-if="svgHover"
                    class="button-feedback__tooltip"
                    @mouseover="svgHover = true"
                    @mouseleave="svgHover = false"
                >
                    <div class="button-feedback__tooltip-text">
                        Leave Feedback
                    </div>
                </div>
            </transition>
            <FeedbackIcon
                class="button-feedback__icon"
                @mouseover="svgHover = true"
                @mouseleave="svgHover = false"
            />
        </a>
    </div>
</template>

<script>
//SVG
import FeedbackIcon from 'Sprites/feedback.svg?inline';

export default {
    components: { FeedbackIcon },
    data() {
        return {
            svgHover: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.button-feedback {
    position: fixed;
    display: inline-block;
    z-index: 99;
    right: 20px;
    bottom: 20px;

    .button-feedback__tooltip {
        position: absolute;
        display: inline-block;
        text-align: center;
        top: -40px;
        left: -80px;
        background-color: #fbfcfe;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
        border-radius: 4px;
        width: 165px;
        height: auto;

        &-text {
            color: #0a294a;
            font-family: $istok-font;
            font-size: 15px;
            font-weight: 700;
            padding: 8px 14px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            right: 5%;
            margin-left: -10px;
            border-width: 8px;
            border-style: solid;
            border-color: #fbfcfe transparent transparent transparent;
        }
    }

    .button-feedback__icon {
        width: 98px;
        height: 95px;
        @media (max-width: 980px) {
            width: 75px;
            height: 72px;
        }
    }
}
</style>
